import React, { memo, useEffect, useState } from 'react';
import firebase from 'firebase';
import Section from '../_section';
import MainTitle from '../main-title';
import Carrossel from '../carrossel';
import BookDetail from '../book-detail';
import Review from '../review';
import OthersTitle from '../others-title';
import OthersBooks from '../others-books';
import InfoButtons from '../info-buttons';
import InfoSmallButtons from '../info-small-buttons';
import Footer from '../footer';
import PwaScreen from '../pwa-screen';
import PushNotificationModal from '../pushNotificationModal';
import songsName from '../../enums/songsName';
import { pauseSong, playSong } from '../../utils/song';
import imgPassatempos from '../../assets/images/passatempos_1.png'
// import AdsModal from "../ads-modal/index";
// import { askUserPermission } from '../../push-notification';

const LandingPage = (props) => {
	const [ isOpenSurprise, setIsOpenSurprise ] = useState(null);
	const isAppInitialized = props.appState === 'firsttimevisit' || props.appState === 'landingpage' ? true : false;

	useEffect(() => {
		if (firebase.messaging.isSupported()) {
			if(window.Notification.permission === 'granted') {
				firebase.messaging().getToken().then(hash => {
					if(hash) console.log('HASH: ', hash);
				});
			}
		}
	}, []);

	useEffect(() => {
		if (props.appState === 'firsttimevisit' && !props.isReading) {
			setTimeout(() => {
				playSong(songsName.MAIN_BACKGROUND)
			}, 999);
		}

		if (props.appState === 'landingpage' && !props.isReading) {
			playSong(songsName.MAIN_BACKGROUND)
		}
		
		if (props.isReading) {
			pauseSong(songsName.MAIN_BACKGROUND)
		}
	}, [props.appState, props.isReading]);

	// const registerServiceWorker = () => {
	// 	if (firebase.messaging.isSupported()) {
	// 		return navigator.serviceWorker.register('/firebase-messaging-sw.js');
	// 	}
	// }

	if (!isAppInitialized) {
		return <div />;
	}

	const changeReader = props.changeReader;

	const onChangeReader = (props) => {
		playSong(songsName.TAP)
		changeReader(props)
	}

	return (
		<>
			<PushNotificationModal showEnable={props.appState === 'landingpage'} />

			<Section parent={props}>
				<PwaScreen />

				<div id="top-empty-space" className='top-empty-space' />
				<MainTitle isOpenSurprise={isOpenSurprise} setIsOpenSurprise={setIsOpenSurprise} />
				<Carrossel
					isOpenSurprise={isOpenSurprise}
					setIsOpenSurprise={setIsOpenSurprise}
					changeToLandingPage={props.changeToLandingPage}
					changeReader={onChangeReader}
				/>
				<BookDetail
					handleOpenReader={props.handleOpenReader}
					changeReader={onChangeReader}
					/>
				<Review />
				
				{props.showGameBanner ? (
					<div
						className='passatempos-banner-wrapper'
						onClick={() => {
							playSong(songsName.TAP)
							window.scrollTo(0, 0);
							props.openGames()
						}}
					>
						<img className='passatempos-banner' src={imgPassatempos} alt='passatempos' />
					</div>
				): <div />}

				<OthersTitle />
				<OthersBooks />
				<InfoButtons appState={props.appState} />
				<InfoSmallButtons />
				<Footer />

				{/* <AdsModal
					open={showAdsModal}
					onRequestClose={setShowAdsModal}
					changeToLandingPage={props.changeToLandingPage}
				/> */}
			</Section>
		</>
	);
};

export default memo(LandingPage);

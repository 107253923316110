import React, { } from 'react';
import './../../assets/styles/Footer.css'
import logoSvg from './../../assets/images/graviola-digital-logo.svg'


const Footer = () => {
    return (
        <div id="footer" className="footer_container">
            <p>
                &copy; Copyright Graviola Digital {new Date().getFullYear()}<br />Todos direitos reservados.
            </p>
        </div>
    );
}

export const handleClick = event => {
    // window.open('www.google.com', '_blank')
}

export default Footer;
